<template>
  <div id="companyintro">
      <h2>应用简介</h2>
    <div class="a_line"></div>
     <div class="company_name">证企通</div>
    <div class="company_intro_content">
      <p>
       证企通-江苏数字矩阵科技有限公司旗下品牌，一站式企业服务综合平台，整合工商财税服务、人力资源服务、知识产权服务、资质审批服务、ISO认证服务、资产评估服务、项目申报服务、智库咨询服务等一站式行业套餐服务，致力于让企业以最低成本享受最优质的服务。
      </p>
    </div>
    
    <!-- <div class="zqt_title">证企通特色</div>
    <div class="appstyle">
        <div class="step"  v-for="(item,index) in showlist" :key="index">
            <div class="stepcontent" >
                <div class="steptitle"><i>0{{index+1}}</i></div>
                <div class="drawing"></div>
                <div class="steptitle1">{{item.step}}</div>
                <div class="stepcontentdetail">{{item.stepdata}}</div>
                <div class="parallelogram"></div>
            </div>
    
        </div>
    </div>
    <div class="zqt_title">成功案例</div>
    <div class="appsuccess">
        <div class="sucin">
            <div class="allsuc">
                <div class="suc_evnet" v-for="(item,index) in showList" :key="index">
                    <div class="indexbox">{{index}}</div>
                    <div class="se_text">{{item.ex}}</div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="zqt_title">应用样图</div>
    <div class="catchimgbox">
        <div v-for="(item,index) in picList" :key="index">
            <img class="catchimg" :src="item" alt="找不到图片">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyIntro",
  data() {
    return {
        showlist:[
            {step:'Step One',stepdata:'为企业免费制定企业规划，匹配优惠资金补助。'},
            {step:'Step Two',stepdata:'为了保证服务质量，所有服务项目首单预付一元，做出结果，再付尾款。'},
            {step:'Step Three',stepdata:'一站式综合性服务平台，以成本价面向市场，减少企业资金成本'},
            {step:'Step Four',stepdata:'采取兜底模式，以结果为导向，项目如未通过，将全额退款，避免企业合作风险。'},
        ],
        showList:[
            {ex:'与南大安高资本研究院达与南大安高资本研究院达成长期战略合作,共同举成长期战略合作，共同举办高校创业创新大赛。办高校创业创新大赛.'},
            {ex:'与三宝投资组成企业融资服务部，帮扶助力企业快速成长壮大。'},
            {ex:'与瑞友联盟,组成合伙制资源合作运营平台，并开设企业运营服务中心。'}
        ],
        picList:[
            require('@/assets/images/aboutus/demopic/1.jpg'),
            require('@/assets/images/aboutus/demopic/2.jpg'),
            require('@/assets/images/aboutus/demopic/3.jpg'),
            require('@/assets/images/aboutus/demopic/4.jpg'),
        ]
    };
  },
  created() {
    window.sessionStorage.setItem("aboutus_index", 3);
  },
  components: {},
};
</script>

<style lang="scss" scoped>
  @media(min-width: 1201px){
    @import '@/components/content/aboutus/appIntro.scss';
  }
  @media(max-width: 1200px){
    @import '@/components/content/aboutus/appIntro_phone.scss';
  }
</style>
